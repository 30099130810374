import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedFolder: null,
    selectedFolderForRename: {},
    selectedFolderForDelete: null,
    toggleFolderDeleteModal: false,
    forceReload: false,
  },
  getters: {
    getSelectedFolder: state => state.selectedFolder,
    getSelectedFolderForRename: state => state.selectedFolderForRename,
    getSelectedFolderForDelete: state => state.selectedFolderForDelete,
    getToggleFolderDeleteModal: state => state.toggleFolderDeleteModal,
    getForceReload: state => state.forceReload,
  },
  mutations: {
    SET_SELECTED_FOLDER: (state, val) => {
      state.selectedFolder = val
    },
    SET_FOLDER_FOR_RENAME: (state, val) => {
      state.selectedFolderForRename = val
    },
    SET_FOLDER_FOR_DELETE: (state, val) => {
      state.selectedFolderForDelete = val
    },
    SET_TOGGLE_FOLDER_DELETE_MODAL: (state, val) => {
      state.toggleFolderDeleteModal = val
    },
    TOGGLE_FORCE_RELOAD: state => {
      state.forceReload = !state.forceReload
    },
  },
  actions: {
    async fetchFoldersList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/folder', {
            params: queryParams,
            headers: {
              WithoutSchoolFilter: true,
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
