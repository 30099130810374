<template>
  <b-card
    no-body
  >

    <div class="table-header m-2">

      <!-- Table Top -->
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h4>
            Folders
          </h4>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-between mb-1 mb-md-0"
        >
          <div>
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <label class="mr-1 mb-0">Search</label>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search"
              class="searchInput"
            />

            <b-button
              v-b-modal.new-folder-modal
              class="ml-1"
              variant="primary"
            >
              <span class="mr-25 align-middle">Create Folder</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <div class="position-relative table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th
                v-for="(col, index) in tableColumns"
                :key="index"
                :class="col.class"
                @click="sortByColumn(col.key)"
              >{{ col.label }}
                <span class="sr-only"> (Click to sort Ascending)</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in tableItems"
              :id="item.id"
              :key="item.id"
              :droppable="false"
              @drop="drop($event, item.id)"
              @dragover="dragOver($event)"
            >
              <td>
                <b-link
                  @click="selectFolder(item)"
                >{{ item.name }}</b-link>
              </td>
              <td>{{ item.items }}</td>
              <td>
                <!-- Dropdown -->
                <b-dropdown
                  variant="link"
                  toggle-class="mx-auto p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item
                    @click="showDeleteFolderModal(item)"
                  >
                    <span>Delete Folder</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showRenameFolderModal(item)"
                  >
                    <span>Rename Folder</span>
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="totalItems && perPage !== 'all'"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <new-folder-modal
      :folder-type="folderType"
      @refetchData="refetchData"
    />

    <rename-folder-modal
      :folder="selectedFolderForRename"
      @refetchData="refetchData"
    />

    <confirmation-modal
      :toggle-modal="toggleFolderDeleteModal"
      :type="'-folder-delete'"
      :title="'Action needed'"
      :message="'Are you sure you want to delete this folder? All realated forms will be unassinged.'"
      @close-modal="closeFolderDeletingModal"
      @confirm="confirmFolderDeleting"
    />

  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BFormInput,
  BButton,
  BPagination,
  BDropdown,
  BDropdownItem,
  BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import storeModule from '@/views/components/folders-list/foldersStoreModule'
import useFoldersList from '@/views/components/folders-list/useFoldersList'
import store from '@/store'
import NewFolderModal from '@/views/components/folders-list/modals/NewFolderModal.vue'
import RenameFolderModal from '@/views/components/folders-list/modals/RenameFolderModal.vue'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  name: 'FoldersList',
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,

    vSelect,

    NewFolderModal,
    RenameFolderModal,
    ConfirmationModal,
  },
  props: {
    folderType: {
      type: String,
      required: true,
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-folders'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchFoldersList,
      deleteFolder,
      setFolder,
      tableColumns,
      tableItems,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      isLoading,
      refFoldersListTable,
      refetchData,
    } = useFoldersList(root, props)

    return {
      fetchFoldersList,
      deleteFolder,
      setFolder,
      tableColumns,
      tableItems,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      totalItems,
      isSortDirDesc,
      isLoading,
      refFoldersListTable,
      refetchData,
    }
  },
  computed: {
    ...mapGetters({
      selectedFolderForRename: 'app-folders/getSelectedFolderForRename',
      selectedFolderForDelete: 'app-folders/getSelectedFolderForDelete',
      toggleFolderDeleteModal: 'app-folders/getToggleFolderDeleteModal',
      forceReload: 'app-folders/getForceReload',
    }),
  },
  watch: {
    $route() {
      if (!this.$route.query.foldersCurrentPage) {
        this.setFilterParams()
      }
    },
    forceReload() {
      this.refetchData()
    },
  },
  async created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
    await this.fetchFoldersList()
  },
  methods: {
    updatePerPage(val) {
      localStorage.setItem('foldersPerPage', val)
      this.perPage = val
    },
    async sortByColumn(column) {
      this.sortBy = column
      this.isSortDirDesc = !this.isSortDirDesc
      await this.fetchFoldersList()
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('foldersPerPage')
      this.currentPage = Number(query.foldersCurrentPage) || 1
      this.perPage = setPerPage(query.foldersPerPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.foldersSearchQuery || ''
    },
    closeFolderDeletingModal() {
      store.commit('app-folders/SET_TOGGLE_FOLDER_DELETE_MODAL', false)
      store.commit('app-folders/SET_FOLDER_FOR_DELETE', null)
    },
    async showDeleteFolderModal(folder) {
      if (folder.items) {
        store.commit('app-folders/SET_FOLDER_FOR_DELETE', folder.id)
        store.commit('app-folders/SET_TOGGLE_FOLDER_DELETE_MODAL', true)
      } else {
        await this.deleteFolder(folder.id)
      }
    },
    async confirmFolderDeleting() {
      await this.deleteFolder(this.selectedFolderForDelete)
      this.closeFolderDeletingModal()
      const route = {
        name: this.$router.currentRoute.name,
      }
      this.$router.push(route)
      store.commit('app-folders/SET_SELECTED_FOLDER', null)
      this.$store.commit('app-folders/TOGGLE_FORCE_RELOAD')
    },
    showRenameFolderModal(folder) {
      store.commit('app-folders/SET_FOLDER_FOR_RENAME', folder)
      this.$nextTick(() => {
        this.$bvModal.show('rename-folder-modal')
      })
    },
    dragOver(event) {
      event.preventDefault()
    },
    drop(event, folderId) {
      const data = JSON.parse(event.dataTransfer.getData('text/plain'))
      data.folder_id = folderId

      this.isLoading = true
      this.setFolder(data).then(() => {
        this.$store.commit('app-folders/TOGGLE_FORCE_RELOAD')
      })
    },
    selectFolder(folder) {
      store.commit('app-folders/SET_SELECTED_FOLDER', folder)

      const route = {
        name: this.$router.currentRoute.name,
        query: {
          folder_id: folder.id,
        },
      }
      this.$router.push(route)
    },
  },
}
</script>

<style lang="scss">
.fixed-width {
  &_created_by {
    width: 200px;
  }
  &_items_inside{
    width: 150px;
  }
  &_actions{
    width: 100px;
  }
  &_actions, &_items_inside, &_created_by {
    padding: 0.72rem 2rem;
  }
}
.per-page-selector {
  width: 90px;
}
#overlay-background {
  min-height: 50px;
}
.searchInput {
  width: 120px;
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
